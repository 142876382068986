<template>
    <div>
        <img class="example-headImg" src="../../assets/image/selected/03-2-2banner.png"/>
        <div class="head-explainBox">
            <div class="head-explainBox-title1">市场渗透率分析</div>
            <div class="head-explainBox-content1">全球某化妆品巨头</div>
            <div class="head-explainBox-line"></div>
            <div class="head-explainBox-title2">项目介绍</div>
            <div class="head-explainBox-content2">经过二十多年的快速发展，客户公司销售区域遍布全国。当市场规模扩大以后，使用销售额来衡量各区域业
                务经营状况的方式无法满足市场发展需求，需要基于数据分析平台和算法体系的支撑，建立一套适合行业业务特点的全新市场衡量指标。同时，落地运
                营级的销售管理系统，也需要借助成熟的解决方案。渗透率已经成为驱动市场份额增长最重要的因素之一，成功的快消和零售企业都在努力逐个地区的
                提高当地渗透率。</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem" @click="goAssignBlock('block'+ 0,50)"><span class="title">业务痛点</span></div>
            <div class="head-list-item" @click="goAssignBlock('block'+ 1,50)"><span class="title">实施方案</span></div>
            <div class="head-list-item" @click="goAssignBlock('block'+ 2,50)"><span class="title">实施收益</span></div>
        </div>
        <div class="container-1" style="display:flex;margin-bottom:10rem" ref="block0">
            <div class="purchase-content-left">
                <div class="purchase-title">业务痛点</div>
            </div>
            <div class="purchase-content-right">
                <div class="selected-box">
                    <div class="selected-card">
                        <div class="title1">难以制订合理指标判定地区业务好坏</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">不同地区的市场存在差异，销售队伍难以找到对标，复制成功经验</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">行政区域与销售区域未能精准匹配，线下推广活动具有复杂度</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">较难评估区域业务饱和度与发展潜力值</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">新的管理思路未能形成指标体系与具体操作，需进一步落地，让管理可执行</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">数据分析与销售策略未能做到端到端打通，分析结果无法直接指导销售</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 84rem;" ref="block1">
            <div class="purchase-content-left">
                <div class="purchase-title">实施方案</div>
            </div>
            <div class="purchase-content-right">
                <img class="solution-img" src="../../assets/image/selected/03-2-2实施方案.svg"/>
            </div>
        </div>
        <div class="container-1" style="display:flex;margin-bottom:10rem" ref="block2">
            <div class="purchase-content-left">
                <div class="purchase-title">实施收益</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-box">
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-2销售管理.svg"/>
                        <div>
                            <div class="title1">销售管理</div>
                            <div class="title2">建立渗透率指标体系，精准判定地区业务好坏</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-2业务评估.svg"/>
                        <div>
                            <div class="title1">业务评估</div>
                            <div class="title2">评估业务发展潜力，成功预测业务回升拐点</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-2区域运营.svg"/>
                        <div>
                            <div class="title1">区域运营</div>
                            <div class="title2">精准划分城市区域，指导实际业务运营，降低企业推广成本</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-2销售预测.svg"/>
                        <div>
                            <div class="title1">销售预测</div>
                            <div class="title2">预测区域业务发展潜力，指导业务发展方向，提高业务销售业绩</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-2业务发展.svg"/>
                        <div>
                            <div class="title1">业务发展</div>
                            <div class="title2">为销售队伍找到对标，复制成功经验，指导经销商业务开发</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-2管理工具.svg"/>
                        <div>
                            <div class="title1">管理工具</div>
                            <div class="title2">建立渗透率管理工具，提高了企业管理者、区域经理以及首席经销商的管理能力</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',
    metaInfo:{
        meta:[
        { name:'keywords', content:'市场渗透率分析,销售管理,业务评估,区域运营,销售预测,业务发展,管理工具' },
        { name:'description', content:'经过二十多年的快速发展，客户公司销售区域遍布全国。当市场规模扩大以后，使用销售额来衡量各区域业 务经营状况的方式无法满足市场发展需求，需要基于数据分析平台和算法体系的支撑，建立一套适合行业业务特点的全新市场衡量指标。同时，落地运 营级的销售管理系统，也需要借助成熟的解决方案。渗透率已经成为驱动市场份额增长最重要的因素之一，成功的快消和零售企业都在努力逐个地区的 提高当地渗透率。' }
        ]
    },
    data() {
        return {
            navOffsetTop: 0,
        };
    },

    mounted() {
        this.activeIndex = '3'
        this.newsIndex = false
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex
        });
        // 监听滚动事件
        window.addEventListener('scroll', this.fiexdNav)
        this.getData()
    },
    destroyed () {
        // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
        window.removeEventListener('scroll', this.fiexdNav)
    },
    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
        goAssignBlock(el, speed) {
        const navHeight = document.querySelector('.head-list').offsetHeight //导航栏的高度
        let _this = this;
        let windowH = window.innerHeight; //浏览器窗口高度
        let h = this.$refs[el].offsetHeight; //模块内容高度
        let t = this.$refs[el].offsetTop - navHeight - speed; //模块相对于内容顶部的距离
        let top = t ; //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置
        let scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop; //滚动条距离顶部高度
        let currentTop = scrollTop; //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
        let requestId;
        //采用requestAnimationFrame，平滑动画
        function step() {
            //判断让滚动条向上滚还是向下滚
            if (scrollTop < top) {
                if (currentTop <= top) {
                    //   window.scrollTo(x,y) y为上下滚动位置
                    window.scrollTo(0, currentTop);
                    requestId = window.requestAnimationFrame(step);
                } else {
                    window.cancelAnimationFrame(requestId);
                }
                //向下滚动
                currentTop += speed;
            } else {
                if (top <= currentTop) {
                    //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
                    window.scrollTo(0, currentTop);
                    requestId = window.requestAnimationFrame(step);
                } else {
                    window.cancelAnimationFrame(requestId);
                }
                //向上滚动
                currentTop -= speed;
            }
        }
        window.requestAnimationFrame(step);
        },
        /** 设置导航条nav到达页面顶部时固定 **/
        // 1.获取导航条nav的offsetTop值，存储在data中（注：之所以不放在滚动事件中，是为了以防添加固定样式后offsetTop值为零,导致页面需要滚动到最上面才可以回到原位）
        getData () {
            this.navOffsetTop = document.querySelector('.head-list').offsetTop + 60
            console.log('this.navOffsetTop',this.navOffsetTop)
        },
        fiexdNav () {
            const navHeight = document.querySelector('.head-list').offsetHeight //导航栏的高度
            // 2.获取当前页面的卷曲高度
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            const nav = document.querySelector('.head-list')
            // 3.判断卷曲高度是否大于等于导航条的offsetTop值
            if (scrollTop > this.navOffsetTop) {
                //   3.1若满足，则给nav导航添加固定样式
                nav.classList.add('fixedNav')
            } else {
                //   3.2若不满足，则删除nav导航的固定样式
                nav.classList.remove('fixedNav')
            }

            /** 当滚动到一定区域时给导航项添加选中样式 **/
            //  1.获取所有锚点元素
            // 2.获取锚点元素的offsetTop值，并收集在一个数组
            const contentsOffsetTop = []
            // contents.forEach(item => {
            //     contentsOffsetTop.push(item.offsetTop)
            //     console.log('2222',item.offsetTop)
            // })
            contentsOffsetTop.push(this.$refs['block0'].offsetTop)
            contentsOffsetTop.push(this.$refs['block1'].offsetTop)
            contentsOffsetTop.push(this.$refs['block2'].offsetTop)
            // console.log('contentsOffsetTop',contentsOffsetTop)
            // console.log('contentsOffsetTop',contentsOffsetTop)
            // 3.获取页面高度
            const pageHeight = window.innerHeight
            // 4.获取nav的子元素
            const navChildren = document.querySelectorAll('.head-list .head-list-item')
            for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[0].classList.add('head-list-item-ischeck')
                }
            if(scrollTop>contentsOffsetTop[0] + this.$refs['block0'].offsetHeight/2){
                for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[1].classList.add('head-list-item-ischeck')
                }
            }
            if(scrollTop>contentsOffsetTop[1] + this.$refs['block1'].offsetHeight/2){
                for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[2].classList.add('head-list-item-ischeck')
                }
            }
        },
        /**
         *设置点击导航跳转到指定选择器对应的锚点元素
        * @param {*} selector
        **/
        skipTo (selector) {
            const navHeight = document.querySelector('.head-list-item').offsetHeight
            // scrollIntoView() js原生方法，实现锚点滚动过渡
            const target = document.querySelector(selector)
            target.scrollIntoView({ behavior: 'smooth' })
            // scrollTo() 把内容滚动到指定的坐标。减去导航高度的目的：导航用定位固定在顶部，如果不减去，导航栏会遮挡一部分内容
            window.scrollTo(0, target.offsetTop - navHeight)
        }
    },
};
</script>

<style lang="less" scoped>
.purchase-content-left{
    width:33rem;
    text-align: justify;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Regular';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-content-right{
    text-align: justify;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 106.8rem;
    height: 71.1rem;
}
.solution-img2{
    width: 107rem;
    height: 41.7rem;
    margin-top: 5rem;
}
.benefits-img{
    width: 80.5rem;
    height: 33.6rem;
}
.selected-box{
    width: 106.8rem;
    height: 20rem;
    display: flex;
    .selected-card{
        width: 17.8rem;
        height: 20rem;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        .title1{
            text-align: justify;
            width: 14.6rem;
            height: 6rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: auto;
            margin-top: 3.5rem;
        }
    }
    .selected-card:hover{
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
        border: 1px solid #7A3C8F;
    }
}
.purchase-box{
    width: 106rem;
    height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    
    .card{
        width: 34.8rem;
        height: 15rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 3rem 0 0 2rem;
        }
        .title1{
            height: 2rem;
            width: 100%;
            font-size: 2rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: 2.5rem 0 0 1.2rem;
        }
        .title2{
            width: 25.2rem;
            height: 4.8rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 2.4rem;

            margin: 1.2rem 0 0 1.2rem;
        }
    }
}
</style>
